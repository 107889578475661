import React, { useEffect } from "react";
import FormInput from "component/common/FormInput";
import {
  AboutFacilityRules,
  CommentRules,
  FirstNameRules,
  LastNameRules,
  NameRules,
  OfficeNameRules,
  OfficeNickNameRules,
  phoneRules,
  phoneRulesOptional,
  ReferenceByRules,
  StateRules,
  ZipCodeRules,
} from "constants/Rules";
import FormRadio from "component/common/FormRadio";
import FormSelect from "component/common/FormSelect";
import FooterButtons from "./FooterButtons";
import { refBy, state } from "../signUpClinician/HourlyConstant";
import FormCleave from "component/common/FormCleave";
import { MAX_LENGTH } from "constants/AppConstants";
import { useNavigate } from "react-router-dom";
import FormCheckbox from "component/common/FormCheckbox";
import PlacesInput from "component/common/PlacesInput";

const SignUpFacilitySecond = ({
  curStep,
  setCurStep,
  register,
  errors,
  control,
  setValue,
  getValues,
  trigger,
  watch,
  resetField,
  unregister,
  formData,
  callAPI,
  loader,
  user,
}) => {
  const navigate = useNavigate();
  const hearOtherOptions = ["Others", "Referral"]; //used to disable / enable other text field

  // handle next button if no errors go to next step
  const handleNext = async () => {
    if (await trigger()) {
      try {
        await callAPI({
          ...formData,

          signupStageCount:
            user?.facilityId?.signupStageCount > 1
              ? user?.facilityId?.signupStageCount
              : 1,
        });
        navigate("/facility/signup?step=2");
        setCurStep(curStep + 1);
      } catch (error) {
        console.log("🚀 ~ handleNext ~ error:", error);
      }
    }
  };

  // if reference by field value is not inside hearOtherOptions reset & unregister other field
  const referenceFrom = watch("referenceFrom");
  useEffect(() => {
    if (!hearOtherOptions.includes(referenceFrom)) {
      resetField("referenceBy");
      unregister("referenceBy");
    }
  }, [referenceFrom]);

  return (
    <>
      <FormInput
        name="officeName"
        id="officeName"
        label="Facility Name: "
        type="text"
        register={register}
        rules={OfficeNameRules}
        errors={errors}
        divClassName="form-group nick-name"
        autoFocus={true}>
        {/* <span>
          This will be displayed to our users, so please include the full name.
          If you have multiple locations, indicate which one it is within the
          name, e.g. St. Luke’s Hospital - Riverdale
        </span> */}
      </FormInput>
      <hr className="my-3" />

      <h3>
        Primary Contact <span style={{ color: "red" }}>*</span>
      </h3>
      <FormInput
        name={"primaryFirstName"}
        id={"primaryFirstName"}
        type={"text"}
        label={"First Name: "}
        register={register}
        rules={FirstNameRules}
        errors={errors}
      />
      <FormInput
        name={"primaryLastName"}
        id={"primaryLastName"}
        type={"text"}
        label={"Last Name: "}
        register={register}
        rules={LastNameRules}
        errors={errors}
      />
      <FormCleave
        trigger={trigger}
        control={control}
        name={"primaryPhone"}
        id={"primaryPhone"}
        label={"Phone Number:"}
        register={register}
        rules={phoneRules}
        errors={errors}
        checkBox={
          <div className="d-flex gap-1 align-items-center mt-1">
            <FormCheckbox
              control={control}
              divClassName="i-agree mt-1"
              className="form-check-input"
              name="allowSMSNotifications"
              options={[{ label: "", value: "allowSMSNotifications" }]}
              defaultValue="allowSMSNotifications"
              register={register}
              errors={errors}></FormCheckbox>
            <div style={{ fontSize: "12px", color: "#80798b" }}>
              I agree to receive SMS messages from application for account
              notifications, new chats, etc.
            </div>
          </div>
        }
      />

      <FormInput
        name={"primaryRole"}
        id={"primaryRole"}
        type={"text"}
        label={"Role:"}
        register={register}
        rules={{}}
        errors={errors}
      />

      <hr></hr>
      <h3>After hours contact</h3>
      <FormInput
        name={"afterHoursName"}
        id={"afterHoursName"}
        type={"text"}
        label={"Name:"}
        register={register}
        rules={NameRules}
        errors={errors}
      />
      <FormCleave
        trigger={trigger}
        name={"afterHoursPhone"}
        label={"Phone number:"}
        rules={phoneRules}
        errors={errors}
        control={control}
      />
      <span className="sub-text">
        Someone who can respond during non-business hours about emergencies or
        shift issues
      </span>
      <hr></hr>
      <h3>Office Admin or Receptionist</h3>
      <FormInput
        name={"frontOfficePersonName"}
        id={"frontOfficePersonName"}
        type={"text"}
        label={"Name:"}
        register={register}
        rules={{}}
        errors={errors}
      />
      <FormCleave
        trigger={trigger}
        name={"frontOfficePersonPhone"}
        label={"Phone number:"}
        rules={phoneRulesOptional}
        errors={errors}
        control={control}
      />
      <hr></hr>
      <h3>Facility Address</h3>
      <FormInput
        name={"officeAddressNickname"}
        id={"officeAddressNickname"}
        label={"Nickname: "}
        placeholder="Address Nickname"
        type={"text"}
        register={register}
        rules={OfficeNickNameRules}
        errors={errors}
        divClassName="form-group nick-name"
      />
      <PlacesInput
        label={"Address: "}
        setValue={setValue}
        register={register}
        trigger={trigger}
        value={getValues("officeAddress1")}
        addressLine="officeAddress1"
        addressState="officeAddressState"
        addressZipcode="officeAddressZipCode"
        error={errors["officeAddress1"]}
        placeholder="Address Line 1"
      />
      <FormInput
        name={"officeAddress2"}
        id={"officeAddress2"}
        type={"text"}
        register={register}
        rules={{}}
        errors={errors}
        divClassName="form-group office-address"
        placeholder="Address line 2"
      />

      <FormSelect
        trigger={trigger}
        options={state}
        placeholder="Select"
        control={control}
        name={"officeAddressState"}
        errors={errors}
        optionValue="value"
        optionLabel="label"
        rules={StateRules}
        label="State:"
      />

      <FormInput
        name={"officeAddressZipCode"}
        id={"officeAddressZipCode"}
        type={"number"}
        label={"Zip code: "}
        trigger={trigger}
        register={register}
        rules={ZipCodeRules}
        errors={errors}
      />
      <p className="mb-0 mt-1">
        Share more about your facility (Highlight for clinicians why they would
        want to pick up a shift at your clinic)
      </p>
      <FormInput
        id="aboutFacility"
        name="aboutFacility"
        divClassName={"h-100 form-group office-address"}
        register={register}
        errors={errors}
        rules={AboutFacilityRules}
        type={"textarea"}
        maxLength={3000}
        placeholder={
          "Example:  We are a small clinic. We see predominately musculoskeletal conditions in middle age adults with a sprinkle of total joints or neuro. Patients are scheduled every 45 minutes and get 1:1 care. We have techs to assist in setting up and cleaning."
        }
      />
      <hr></hr>
      <div className="form-group hear-about">
        <p>How did you hear about Purple PRN?</p>
        <FormRadio
          divClassName="pt-radio"
          name={"referenceFrom"}
          options={refBy}
          register={register}
          rules={ReferenceByRules}
          errors={errors}
          className="pt-radio"
        />
      </div>

      <FormInput
        disabled={!hearOtherOptions.includes(referenceFrom)}
        divClassName="textarea heartext"
        name={"referenceBy"}
        id={"referenceBy"}
        type={"textarea"}
        label={""}
        register={register}
        rules={hearOtherOptions.includes(referenceFrom) ? CommentRules : {}}
        errors={errors}
        placeholder="Let us know if any other way you have heard about Purple PRN."
        maxLength={MAX_LENGTH}
      />

      <FooterButtons
        // leftLabel={"Prev"}
        rightLabel={"Save & Next"}
        setCurStep={setCurStep}
        curStep={curStep}
        onRightClick={() => handleNext()}
        loader={loader}
      />
    </>
  );
};

export default SignUpFacilitySecond;
